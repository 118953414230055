import $ from 'jquery';

$('.c-tab1 .c-tab1Botton1__item').on('click', function () {
	let index = $('.c-tab1 .c-tab1Botton1__item').index(this);
	$('.c-tab1 .c-tab1__panel, .c-tab1 .c-tab1Botton1__item').removeClass(
		'is-active',
	);
	$(this).addClass('is-active');
	$('.c-tab1 .c-tab1__panel').eq(index).addClass('is-active');
});
